const Layout = () =>
  import(/* webpackChunkName: "group-master" */ "@/layouts/menu/Main");
const MasterKeuangan = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/master/master-keuangan/Main"
  );
const MasterBiayaSP = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/master/master-biaya-sp/Main"
  );
const Bank = () =>
  import(/* webpackChunkName: "group-master"*/ "../views/master/bank/Main");
const Beasiswa = () =>
  import(/* webpackChunkName: "group-master"*/ "../views/master/beasiswa/main");
const CopyBeasiswa = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/master/beasiswa/lanjut"
  );
const Akun = () =>
  import(/* webpackChunkName: "group-master"*/ "../views/master/akun/Main");

const JenisBiaya = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/master/jenisbiaya/Main"
  );

const KelompokHonor = () =>
  import(
    /* webpackChunkName: "group-master"*/ "../views/master/kelompok-honor/Main"
  );

const Honorarium = () =>
  import(/* webpackChunkName: "group-master"*/ "../views/master/honor/Main");

export const routes = {
  path: "/master",
  name: "master-route",
  component: Layout,
  children: [
    {
      name: "bank",
      path: "bank",
      component: Bank,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "master-keuangan",
      path: "master-keuangan",
      component: MasterKeuangan,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "master-biaya-sp",
      path: "master-biaya-sp",
      component: MasterBiayaSP,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "beasiswa",
      path: "beasiswa",
      component: Beasiswa,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "copy-beasiswa",
      path: "copy-beasiswa",
      component: CopyBeasiswa,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "transfer-beasiswa",
      path: "transfer-beasiswa",
      component: CopyBeasiswa,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "akun",
      path: "akun",
      component: Akun,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "jenis-biaya",
      path: "jenis-biaya",
      component: JenisBiaya,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "kelompok-honor",
      path: "kelompok-honor",
      component: KelompokHonor,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
    {
      name: "formathonor",
      path: "formathonor",
      component: Honorarium,
      meta: {
        requiresAuth: true,
        general: false,
        ability: ["master"],
      },
    },
  ],
};
